import React from "react"
import styled from "styled-components"

export const ProductBoard = ({
  logo,
  description = "",
  productName = "",
  pitch = "",
  buttonText = "Enroll Today",
  ctaLink = "/",
  borderColor = "",
  fontSize = "20px",
}) => {
  // let shadowColor = `product-board__${borderColor}-box-shadow`
  let topBorderColor = `product-board__${borderColor}-top-border`
  return (
    <ProductContainer className={topBorderColor}>
      <LogoContainer>
        <LogoLink href={ctaLink} target="blank" rel="referrer">
          <LogoImage
            src={logo}
            height="60px"
            max-width="100%"
            alt={productName}
          />
        </LogoLink>
      </LogoContainer>
      <DescriptionH1>{description}</DescriptionH1>
      <PitchH1 style={{ fontSize: fontSize }}>
        The <ProductName>{productName} </ProductName>
        {pitch}
      </PitchH1>
      <CtaContainer>
        <a href={ctaLink} target="_blank" rel="noreferrer">
          <button className="btn btn-primary btn-large">{buttonText}</button>
        </a>
      </CtaContainer>
    </ProductContainer>
  )
}

const ProductContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(4, minmax(50px, auto));
  grid-template-areas:
    "logo"
    "description"
    "pitch"
    "cta";
  row-gap: 15px;
  justify-items: center;
  text-align: center;
  width: 350px;
  height: 600px;
  margin: 20px;
  box-shadow: black 0px 0px 5px 0px;
`

const LogoContainer = styled.div`
  grid-area: logo;
  margin-top: 25px;
`
const LogoImage = styled.img`
  /* max-width: 80%; */
  max-width: 100%;
`

const LogoLink = styled.a`
  cursor: pointer;
`

const DescriptionH1 = styled.h1`
  grid-area: description;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  width: 70%;

  @media screen and (max-width: 768px) {
    font-size: clamp(1.4rem, 3vw, 3rem);
  }
`

const PitchH1 = styled.h1`
  grid-area: pitch;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 900;
  width: 70%;

  @media screen and (max-width: 768px) {
    font-size: clamp(1rem, 3vw, 3rem);
  }
`
const CtaContainer = styled.div`
  grid-area: cta;
  align-self: flex-end;
  padding-bottom: 20px;
`
const ProductName = styled.span`
  font-weight: 900;
  line-height: 1;
`
