import React from "react"
import styled from "styled-components"
import { Container, Col, Row } from "react-bootstrap"
import Forbes from "../../assets/images/forbes-logo.png"
import Inc from "../../assets/images/inc-logo.png"
import Yahoo from "../../assets/images/yahoo-finance-logo.png"
import Ent from "../../assets/images/entrepreneur-logo.png"
import Go from "../../assets/images/go-creative-show.png"

export const AsSeenIn = ({ intro }) => {
  return (
    <section className="as-seen-in__section">
      <Container>
        <Row className="as-seen-in__format py-4">
          <Col xs={3}>
            <Content>{intro}</Content>
          </Col>
          <Col className="as-seen-in__h-center">
            <Content>
              <img
                src={Forbes}
                alt="Forbes Inc Logo"
                className="as-seen-in__images"
              />
            </Content>
            <Content>
              <img src={Inc} alt="Inc Logo" className="as-seen-in__images" />
            </Content>
            <Content>
              <img
                src={Yahoo}
                alt="Yahoo Inc Logo"
                className="as-seen-in__images"
              />
            </Content>
            <Content>
              <img
                src={Ent}
                alt="Entrepreneur Inc Logo"
                className="as-seen-in__images"
              />
            </Content>
            <Content>
              <img
                src={Go}
                alt="Go Creative Show Logo"
                className="as-seen-in__images"
              />
            </Content>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

const Content = styled.span`
  width: 100%;
  font-size: clamp(1.3rem, 1.8vw, 2rem);
`
