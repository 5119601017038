import React from "react"
import styled from "styled-components"

import FilmmakerLogo from "../../assets/images/filmmaker-logos.png"

export default function Filmmakers({ intro, theme }) {
  return (
    <>
      <FilmmakerContainer className={theme}>
        <FilmmakerH1>{intro}</FilmmakerH1>
        <FilmmakersImage
          src={FilmmakerLogo}
          alt="Mr. Paul Xavier Logo"
          padding="50px"
        />
      </FilmmakerContainer>
    </>
  )
}

const FilmmakerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 0;
  padding: 0;
  background: white;
  height: auto;
  width: 100vw;
`
const FilmmakerH1 = styled.h1`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: clamp(2rem, 4vw, 6rem);
  font-size: 2rem;
  text-align: center;
  font-weight: 900;
  width: 90%;
  padding: 20px;
  margin: 0px;
  margin-bottom: 20px;

  @media screen and (max-width: 420px) {
    font-size: 1.4rem;
  }
`
const FilmmakersImage = styled.img`
  max-width: 90%;
`
