import React from "react"
import styled from "styled-components"
import { Btn } from "../Btn"

export default function CaseStudyCTA() {
  return (
    <>
      <CaseStudyWrapper>
        <CaseStudyH1>
          How To Add $10,000 In Monthly Recurring Revenue (MRR) To Your Video
          Agency In Just 90 Days
        </CaseStudyH1>
        <Btn
          primary="true"
          round="true"
          to="/case-study1"
          className="case-study__margin"
        >
          Yes! I Want Monthly Recurring Revenue (MRR)
        </Btn>
      </CaseStudyWrapper>
    </>
  )
}

const CaseStudyWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  padding-top: 45px;
  padding-bottom: 50px;
  background: #343a40;
  height: auto;
  width: 100vw;
`

const CaseStudyH1 = styled.h1`
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: clamp(2rem, 4vw, 6rem);
  font-size: 2rem;
  color: white;
  text-align: center;
  font-weight: 900;
  width: 90%;
  padding: 20px;
  margin: 0px;
  margin-bottom: 20px;
  color: white;

  @media screen and (max-width: 768px) {
    /* font-size: clamp(1.5rem, 4vw, 3rem); */
    font-size: 1rem;
    width: 90%;
    margin: 1rem;
    padding: 1rem;
  }
`
