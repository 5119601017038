import styled from "styled-components"
import { Link } from "gatsby"

export const Btn = styled(Link)`
  display: flex;
  justify-content: center;
  text-align: center;
  background: ${({ primary }) => (primary ? "#2E7D32" : "#0AA400")};
  /* white-space: nowrap; */
  padding: ${({ big }) => (big ? "16px 40px" : "10px 32px")};
  color: #fff;
  font-size: ${({ big }) => (big ? "24px" : "20px")};
  outline: none;
  border: none;
  min-width: 100px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  border-radius: ${({ round }) => (round ? "10px" : "none")};
  color: white;

  &:hover {
    background: ${({ primary }) => (primary ? "#215924" : "	#077000")};
    color: #fff;
    text-decoration: none;
    transform: translateY(-2px);
  }

  @media screen and (max-width: 768px) {
    font-size: ${({ big }) => (big ? "19.2px" : "16px")};
    /* margin-bottom: 25px; */
  }
`
