import React from "react"
import { Container } from "react-bootstrap"
import { Btn } from "../Btn"
import styled from "styled-components"
import Video from "../../assets/videos/mountain-1.mp4"

const Hero = () => {
  return (
    <Container fluid className="hero-container">
      <HeroContainer>
        <HeroBg>
          <VideoBg
            src={Video}
            type="video/mp4"
            autoPlay
            loop
            muted
            playsIinline
          />
        </HeroBg>
        <HeroContent>
          <HeroItems>
            <HeroH1>Build Lasting Wealth As A Content Creator</HeroH1>
            <HeroP>
              Join thousands of content creators achieving higher incomes,
              faster and more cost effective than traditional film schools
            </HeroP>
            <Btn primary="true" round="true" to="case-study1">
              Watch Free Training
            </Btn>
          </HeroItems>
        </HeroContent>
      </HeroContainer>
    </Container>
  )
}

export default Hero

const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  /* padding: 0 1rem; */
  position: relative;
  /* margin-top: -80px; */
  color: #fff;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  }
`

const HeroBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  /* margin-top: 100px; */

  @media screen and (max-width: 768px) {
    /* margin-top: 105px; */
  }
`

const HeroContent = styled.div`
  z-index: 3;
  height: calc(100vh - 80px);
  max-height: 100%;
  padding: 10%;
`

const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  max-height: 100%;
  padding: 0;
  color: #fff;
  line-height: 1.1.%;
  font-weight: bold;
  padding-top: 100px;
`

const HeroH1 = styled.h1`
  font-family: "Raleway", "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 5rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
  letter-spacing: 3px;
  padding: 0 1rem;

  @media (max-width: 1280px) {
    font-size: 4rem;
  }

  @media (max-width: 970px) {
  }

  @media (max-width: 678px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 420px) {
    font-size: 2rem;
    font-weight: 700;
  }
`

const HeroP = styled.p`
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 2rem;
  max-width: 700px;

  @media (max-width: 1199px) {
  }

  @media (max-width: 970px) {
  }

  @media (max-width: 678px) {
  }

  @media screen and (max-width: 420px) {
    font-size: 1.2rem;
    font-weight: 400;
  }
`
