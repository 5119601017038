import React from "react"
import styled from "styled-components"
import { Container, Row } from "react-bootstrap"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { FaCheck } from "@react-icons/all-files/fa/FaCheck"
import { FaThumbsUp } from "@react-icons/all-files/fa/FaThumbsUp"

export default function BusinessEducation() {
  return (
    <>
      <section className="bus-ed-background">
        <Container>
          <Row className="bus-ed-h1 d-flex justify-content-center">
            <h1 className="bus-ed-h1">
              World Class Business Education For Content Creators
            </h1>
          </Row>
          <div className="bus-ed-background">
            <Row className="bus-ed-white d-flex justify-content-center">
              <p>
                Currently, if someone wants to be in the video production
                industry, and they go to film school, it's because they...
              </p>
              <p style={{ padding: "0px 20px" }}>
                <CheckmarkIcon /> Want to get a job in the video industry.
                <br />
                <CheckmarkIcon /> Want to start their own video production
                company one day & make movies.
              </p>
              <p>
                The sole purpose of our programs is to help our content creators
                grow their income
                <Italic> (bigger)</Italic>, in less time
                <Italic> (faster)</Italic>, for the least amount of time and
                money investment possible
                <Italic> (cost effective)</Italic>.
              </p>
              <p>
                Rather than spend over $100,000 on a degree from a Film School
                and studying for 4 years before you begin creating content,
                growing your revenue & gaining traction...
              </p>
              <p>Our programs focus on producing real world results.</p>
              <p>
                <Bold>We do NOT Offer:</Bold>
                <br />
                <CloseIcon />
                Degrees
                <br />
                <CloseIcon />
                Awards
                <br />
                <CloseIcon />
                Certificates
              </p>
              <p>
                As we believe they are a false measure of success that leads
                people to feeling "entitled" to results.
              </p>
              <p>
                In the video industry, the most important thing is{" "}
                <Italic>delivering</Italic> results and having the skills.
              </p>
              <p>
                So our focus is on creating products that shorten the path to
                what people truly want - high paying careers and growing
                production companies.
              </p>
              <p>
                When our team ask questions of what to do in our business, these
                considerations come first:
              </p>
              <p style={{ padding: "0 0 0 20" }}>
                <BusEdThumbsUpIcon />
                Will it help our clients grow?
                <br />
                <BusEdThumbsUpIcon />
                Will it help increase the speed of their success?
                <br />
                <BusEdThumbsUpIcon />
                Will this make it less expensive for them to succeed?
              </p>
              <p>
                The truth we keep in mind is that people don't want licenses,
                degrees, or certifications just to have them. They want them for
                the sake of an outcome, typically higher earning potential in
                their career, increased productivity, more fulfilling work, and
                meaningful relationships with their coworkers, team members and
                customers.
              </p>
              <p>
                We will continue to measure our company and products against
                these outcomes. We are relentlessly focused on ensuring we
                provide the most efficient and effective paths to real world
                results for our clients.
              </p>
            </Row>
          </div>
        </Container>
      </section>
    </>
  )
}

const Italic = styled.span`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-style: italic;
`

const Bold = styled.span`
  font-weight: 900;
  line-height: 1;
`

const CloseIcon = styled(FaTimes)`
  color: red;
  font-size: 1.3rem;
  margin-bottom: 3px;
  margin-right: 0.3rem;
  margin-left: 20px;
`
const CheckmarkIcon = styled(FaCheck)`
  color: green;
  font-size: 1.3rem;
  margin-bottom: 3px;
  margin-right: 0.3rem;
`

const BusEdThumbsUpIcon = styled(FaThumbsUp)`
  font-size: 1.3rem;
  color: hsl(128, 82%, 40%);
  margin-bottom: 3px;
  margin-right: 0.3rem;
`
