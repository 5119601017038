import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { NavigationBar } from "./NavigationBar"
import Dropdown from "./Dropdown"
import Hero from "./Hero"
import { AsSeenIn } from "./AsSeenIn"
import Products from "./Products"
import BusinessEducation from "./BusinessEducation"
import Filmmakers from "./Filmmakers"
import CaseStudyCTA from "./CaseStudyCTA"
import SocialFooter from "./SocialFooter"
import DisclosureFooter from "./DisclosureFooter"
import favicon from "../../assets/images/nlc-favicon.png"

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Helmet
        title="Nexl Level Creators | The Best Online Courses For Learning Content Creation"
        meta={[
          {
            name: "description",
            content:
              "The Best Online Courses For Learning Content Creation. Commit to daily growth by enrolling in immersive, online classes with the worlds best instructors. Unlock your creative potential.",
          },
          { name: "keywords", content: "...." },
        ]}
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
      />
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <NavigationBar toggle={toggle} />
      <Hero />
      <AsSeenIn intro="As Seen In:" />
      <Products />
      <BusinessEducation />
      <Filmmakers
        intro="Join Over 10,000 Rockstar Content Creators"
        theme="themes__panel-light"
      />
      <CaseStudyCTA />
      <SocialFooter />
      <section className="themes__panel-black">
        <DisclosureFooter />
      </section>
      {/* <div className="spacer1"></div> */}
    </>
  )
}
